import React from "react";

export const HelpIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7371 22.8855C18.2599 22.8855 22.7371 18.4083 22.7371 12.8855C22.7371 7.3626 18.2599 2.88545 12.7371 2.88545C7.21421 2.88545 2.73706 7.3626 2.73706 12.8855C2.73706 18.4083 7.21421 22.8855 12.7371 22.8855Z"
      stroke="#8253E7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.82709 9.88546C10.0622 9.21713 10.5262 8.65356 11.137 8.29459C11.7478 7.93562 12.466 7.8044 13.1643 7.92417C13.8625 8.04394 14.4959 8.40698 14.9522 8.94899C15.4084 9.49099 15.6581 10.177 15.6571 10.8855C15.6571 12.8855 12.6571 13.8855 12.6571 13.8855"
      stroke="#8253E7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.7371 17.8855H12.7471"
      stroke="#8253E7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
