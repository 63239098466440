import { inject, observer } from "mobx-react";
import React from "react";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import {
  captionStyles,
  linkStyles,
  timelineContentStyles,
  TransferItemStatus,
  TransferItemTitle,
  TransferTimelineStep,
} from "./TransferTimeline";
import { ETHLogoIcon } from "@/icons/ETHLogoIcon";
import { BSCLogoIcon } from "@/icons/BSCLogoIcon";
import { viewTransactionUrl } from "@/helpers/viewTransactionUrl";

const _TimelineItemBridgeSource = ({
  transferRequest,
  resolveCurrentStep,
  isCompleted,
  transaction,
  isProcessing,
  createdAt,
  completedAt,
  transactionHash,
  approvalTransactionHash,
  allowance,
  isApprovalCompleted,
  isApprovalProcessing,
  isFeeCompleted,
  approvalConfirmations,
  sourceConfirmations,
}) => {
  const isCurrentStep = resolveCurrentStep() === "bridge:source";
  console.log(`isCurrentStep`, isCurrentStep);

  return (
    <>
      <TimelineItem
        sx={[
          { transition: "opacity 1s linear", minHeight: "130px" },
          isApprovalProcessing || isApprovalCompleted || isFeeCompleted
            ? { opacity: "1" }
            : { opacity: "0.5" },
        ]}
      >
        <TimelineOppositeContent
          sx={{ m: "2px 0", paddingRight: "20px", maxWidth: "140px" }}
          align="right"
          color="white"
        >
          <TransferItemStatus
            completedAt={isApprovalCompleted}
            isProcessing={isApprovalProcessing}
          />
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={{ maxHeight: "0" }} />
          <TransferTimelineStep
            completed={isApprovalCompleted}
            isProcessing={isApprovalProcessing}
          />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={timelineContentStyles}>
          <TransferItemTitle
            icon={
              transferRequest.from === "ETH" ? <ETHLogoIcon /> : <BSCLogoIcon />
            }
          >
           Approving Tokens
          </TransferItemTitle>
          <div style={{ marginTop: "12px" }}>
            {!isApprovalCompleted && (
              <p style={captionStyles}>
                {transferRequest.from === "BSC" && (
                  <>Approve the PRT BEP20 tokens to be locked.</>
                )}
                {transferRequest.from === "ETH" && <>Burning tokens</>}
              </p>
            )}
            {isApprovalProcessing && (
              <a
                href={viewTransactionUrl(
                  transferRequest.from,
                  approvalTransactionHash
                )}
                style={linkStyles}
                target="_blank"
                rel="noopener noreferrer"
              >
                Confirming transaction {approvalConfirmations} / 10
              </a>
            )}
            {isApprovalCompleted && (
              <>
                <p style={captionStyles}>
                  Your tokens have been approved to be bridged.
                </p>
                <a
                  href={viewTransactionUrl(
                    transferRequest.from,
                    approvalTransactionHash
                  )}
                  style={linkStyles}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Transcation
                </a>
              </>
            )}
          </div>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem
        sx={[
          { transition: "opacity 1s linear", minHeight: "130px" },
          isProcessing || completedAt || isApprovalCompleted
            ? { opacity: "1" }
            : { opacity: "0.5" },
        ]}
      >
        <TimelineOppositeContent
          sx={{ m: "2px 0", paddingRight: "20px", maxWidth: "140px" }}
          align="right"
          color="white"
        >
          <TransferItemStatus
            completedAt={completedAt}
            isProcessing={isProcessing}
          />
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={{ maxHeight: "0" }} />
          <TransferTimelineStep
            completed={completedAt}
            isProcessing={isProcessing}
          />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={timelineContentStyles}>
          <TransferItemTitle
            icon={
              transferRequest.from === "ETH" ? <ETHLogoIcon /> : <BSCLogoIcon />
            }
          >
           Transferring BSC Tokens
          </TransferItemTitle>
          <div style={{ marginTop: "12px" }}>
            {!completedAt && (
              <p style={captionStyles}>
                {transferRequest.from === "BSC" && <>Sending your PRT BEP20 tokens to the bridge to be locked.</>}
                {transferRequest.from === "ETH" && <>Burning tokens</>}
              </p>
            )}
            {isProcessing && (
              <a
                href={viewTransactionUrl(transferRequest.from, transactionHash)}
                style={linkStyles}
                target="_blank"
                rel="noopener noreferrer"
              >
                Confirming transaction {sourceConfirmations} / 6
              </a>
            )}
            {completedAt && (
              <>
                <p style={captionStyles}>Your tokens have been locked.</p>
                <a
                  href={viewTransactionUrl(
                    transferRequest.from,
                    transactionHash
                  )}
                  style={linkStyles}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View transaction
                </a>
              </>
            )}
          </div>
        </TimelineContent>
      </TimelineItem>
    </>
  );
};

const mapMobxToProps = ({ transfer }) => {
  const bridgeFee = transfer.transactionMap.get("bridge:fee");
  const record = transfer.transactionMap.get("bridge:source");
  const approved = transfer.transactionMap.get("bridge:source:approval");
  return {
    transferRequest: transfer.transferRequest,
    resolveCurrentStep: transfer.resolveCurrentStep,
    isCompleted: record.isCompleted,
    transaction: record.transaction,
    isProcessing: record.isProcessing,
    createdAt: record.createdAt,
    completedAt: record.completedAt,
    allowance: record.allowance,
    isApprovalCompleted: approved.completedAt,
    isApprovalProcessing: approved.isProcessing,
    isFeeCompleted: bridgeFee.completedAt,
    transactionHash: record.transactionHash,
    approvalTransactionHash: approved.transactionHash,
    approvalConfirmations: approved.confirmations,
    sourceConfirmations: record.confirmations,
  };
};

export const TimelineItemBridgeSource = inject(mapMobxToProps)(
  observer(_TimelineItemBridgeSource)
);
