import React from "react";

export const WalletSmallIcon = () => (
    <svg
        width="42"
        height="35"
        viewBox="0 0 42 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M41.2191 11.1713H40.438V0.743402C40.438 0.332788 40.0884 0 39.6571 0H6.64779C4.20866 0 2.0724 1.25724 0.915845 3.12642C0.901936 3.14885 0.887886 3.17128 0.874261 3.19371C0.318197 4.11843 0 5.18881 0 6.32851V28.6704C0 32.1604 2.98214 35 6.64779 35H41.2191C41.246 35 41.2726 34.9986 41.299 34.9961C41.3728 34.9889 41.4438 34.972 41.5099 34.9468C41.797 34.8369 42 34.5694 42 34.2567V28.6714V17.4998V11.9147C42 11.5041 41.6504 11.1713 41.2191 11.1713ZM40.438 27.928H30.2518C27.4477 27.928 25.1664 25.7556 25.1664 23.0852C25.1664 20.4156 27.4477 18.2435 30.2518 18.2435H40.438V27.928ZM6.64779 1.48707H38.8759V11.1713H37.1629V9.1216V7.72559V3.53649C37.1629 3.12588 36.8133 2.79309 36.3817 2.79309H3.17842C4.08788 1.98376 5.30744 1.48707 6.64779 1.48707ZM6.64779 11.1713C4.65033 11.1713 2.9187 10.0685 2.0873 8.46926H35.6009V9.1216V11.1713H6.64779ZM35.6009 4.28017V6.98219H1.60943C1.57892 6.76817 1.56203 6.5505 1.56203 6.32851C1.56203 5.59672 1.73447 4.90264 2.04103 4.28017H35.6009ZM1.56203 28.6705V10.3999C2.78231 11.7798 4.60931 12.6584 6.64779 12.6584H36.3817H39.6571H40.438V16.7564H30.2518C26.5865 16.7564 23.6043 19.5954 23.6043 23.0852C23.6043 26.5755 26.5865 29.415 30.2518 29.415H40.438V33.5131H6.64779C3.84334 33.5129 1.56203 31.3406 1.56203 28.6705Z"
            fill="#FB6C1C"
        />
    </svg>
);
