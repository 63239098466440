import React from "react";

export const CopyToClipboard = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 32 32"
    >
      <path
        stroke="white"
        d="M23.783 25.851v-17.501h-2.149v3.275h-10.951v-3.275h-2.149v17.501h15.249zM16.158 6.201c-0.614 0-1.075 0.461-1.075 1.075s0.461 1.075 1.075 1.075c0.614 0 1.075-0.461 1.075-1.075s-0.461-1.075-1.075-1.075v0zM23.783 6.201c1.177 0 2.201 0.973 2.201 2.149v17.501c0 1.177-1.023 2.149-2.201 2.149h-15.249c-1.177 0-2.201-0.973-2.201-2.149v-17.501c0-1.177 1.023-2.149 2.201-2.149h4.555c0.461-1.28 1.637-2.201 3.070-2.201s2.61 0.921 3.070 2.201h4.554z"
      ></path>
    </svg>
  );
};
