import React from "react";

export const WalletIcon = () => (
  <svg
    width="66"
    height="56"
    viewBox="0 0 66 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M64.7729 17.8741H63.5454V1.18944C63.5454 0.532461 62.9961 0 62.3183 0H10.4465C6.6136 0 3.25662 2.01159 1.43919 5.00227C1.41733 5.03816 1.39525 5.07405 1.37384 5.10993C0.500024 6.58949 0 8.3021 0 10.1256V45.8727C0 51.4567 4.68622 56 10.4465 56H64.7729C64.8151 56 64.857 55.9978 64.8985 55.9937C65.0145 55.9823 65.126 55.9552 65.2299 55.9148C65.6811 55.7391 66 55.311 66 54.8108V45.8742V27.9997V19.0635C66 18.4065 65.4507 17.8741 64.7729 17.8741ZM63.5454 44.6847H47.5386C43.1321 44.6847 39.5471 41.2089 39.5471 36.9363C39.5471 32.6649 43.1321 29.1896 47.5386 29.1896H63.5454V44.6847ZM10.4465 2.37932H61.0908V17.8741H58.3988V14.5946V12.3609V5.65839C58.3988 5.00141 57.8495 4.46895 57.1713 4.46895H4.99466C6.42381 3.17401 8.34027 2.37932 10.4465 2.37932ZM10.4465 17.8741C7.30766 17.8741 4.58652 16.1096 3.28004 13.5508H55.9442V14.5946V17.8741H10.4465ZM55.9442 6.84827V11.1715H2.52911C2.48116 10.8291 2.45462 10.4808 2.45462 10.1256C2.45462 8.95476 2.7256 7.84422 3.20733 6.84827H55.9442ZM2.45462 45.8729V16.6399C4.3722 18.8478 7.24321 20.2534 10.4465 20.2534H57.1713H62.3183H63.5454V26.8102H47.5386C41.7787 26.8102 37.0925 31.3527 37.0925 36.9363C37.0925 42.5207 41.7787 47.064 47.5386 47.064H63.5454V53.6209H10.4465C6.03954 53.6207 2.45462 50.1449 2.45462 45.8729Z"
      fill="#FB6C1C"
    />
  </svg>
);
