// TODO: Enable eslint
/* eslint-disable */
import React, { useState } from "react";
import { inject, observer } from "mobx-react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import { CloseIcon } from "@/icons/CloseIcon";
import {
  body2Styles,
  captionStyles,
  TransferTimeline,
} from "./TransferTimeline";
import { TransferDialogActions } from "./TransferDialogActions";
import { ethers } from "ethers";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    margin: "15px",
    maxWidth: "712px",
    width: "100%",
    backgroundColor: "#282A30",
  },
  transferDialog: {
    position: "relative",
  },
  transferDialogCloseBtn: {
    position: "absolute",
    top: "25px",
    right: "32px",
    width: "40px",
    height: "40px",
    margin: "8px",
    lineHeight: 0,
  },
  transferDialogTitle: {
    padding: 0,
    flexDirection: "column",
  },
  transferDialogContent: {
    padding: "32px",
  },
  textDescription: {
    marginBottom: "32px",
  },
  copyWrapper: {
    display: "flex",
    marginBottom: "32px",
  },
  disabledField: {
    padding: "6px 16px",
    border: `1px solid ${theme.palette.border.light}`,
    borderRadius: "5px 0 0 5px",
    overflow: "hidden",
  },
  copyBtn: {
    borderRadius: "0 5px 5px 0",
  },
  qrcodeWrapper: {
    maxWidth: "220px",
    margin: "0 auto 32px",
    "& > img": {
      width: "100%",
    },
  },
  dialogActionsButton: {
    display: "block",
    textAlign: "center",
    padding: 0,
  },
  dialogTitleContainer: {
    position: "relative",
    padding: "32px",
    borderBottom: "1px solid #2C2E35",
  },
  dialogHeader: {
    fontSize: "16px",
    color: "#7230FF",
  },
  confirmationHeader: {
    fontSize: "14px",
    color: "#bdbdbd",
    fontWeight: "300",
  },
  confirmContainer: {
    padding: "30px 40px",
    border: `1px solid gray`,
    borderRadius: " 5px",
    maxWidth: "400px",
  },
  primaryButton: {
    backgroundColor: "red",
    color: "white",
    height: "35px",
    width: "120px",
    marginTop: "15px",
    color: "white",
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: "#8d0000",
    },
  },
  secondaryButton: {
    backgroundColor: "#5a5a5a",
    color: "white",
    height: "35px",
    width: "120px",
    marginTop: "15px",
    color: "white",
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: "#3d3d3d",
    },
  },
}));

const _TransferDialog = ({
  transferRequest,
  transferDialogOpen,
  setTransferDialogOpen,
  isCompleted,
}) => {
  const classes = useStyles();
  const [confirmDialog, setConfirmDialog] = useState(false);

  const handleCloseDialog = () => {
    setConfirmDialog(true);
  };
  const handleCloseConfirmDialog = () => {
    setConfirmDialog(false);
    setTransferDialogOpen(false);
  };

  if (!transferRequest) return;

  return (
    <Dialog
      open={transferDialogOpen}
      onClose={() => setConfirmDialog(true)}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <Dialog
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        classes={{
          paper: classes.dialogPaper,
        }}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className={classes.confirmContainer}>
          <h2 className={classes.confirmationHeader}>
            Are you sure you want to cancel your transfer before you complete
            the bridge?
          </h2>
          <h2 className={classes.confirmationHeader}>
            Any completed transactions will be forfeit.
          </h2>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              onClick={handleCloseConfirmDialog}
              className={classes.primaryButton}
            >
              STOP BRIDGE
            </Button>
            <Button
              onClick={() => setConfirmDialog(false)}
              className={classes.secondaryButton}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
      <div className={classes.transferDialog}>
        <div className={classes.dialogTitleContainer}>
          <IconButton
            onClick={handleCloseDialog}
            className={classes.transferDialogCloseBtn}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle
            className={classes.transferDialogTitle}
            disableTypography
          >
            <h2 className={classes.dialogHeader}>
              Transfer#{transferRequest.transferId}
            </h2>
            <p style={{ ...captionStyles, marginTop: "6px" }}>
              Bridging {transferRequest.amount / Math.pow(10, 9)} PORNROCKET to
              INTI
            </p>
          </DialogTitle>
        </div>
        <DialogContent className={classes.transferDialogContent}>
          <TransferTimeline isCompleted={isCompleted} />
          <TransferDialogActions />
        </DialogContent>
      </div>
    </Dialog>
  );
};

const mapMobxToProps = ({ transfer }) => {
  return {
    transferRequest: transfer.transferRequest,
    transferStep: transfer.currentStep,
    transferTransactionMap: transfer.transactionMap,
    transferDialogOpen: transfer.transferDialogOpen,
    setTransferDialogOpen: transfer.setTransferDialogOpen,
    isCompleted: transfer.transactionMap.get("bridge:destination").completedAt,
  };
};

export const TransferDialog = inject(mapMobxToProps)(observer(_TransferDialog));
