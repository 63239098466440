import React from "react";

export const ETHLogoIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 24C18.6275 24 24 18.6275 24 12C24 5.37258 18.6275 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6275 5.37258 24 12 24Z" fill="white"/>
    <path d="M12.104 4.5L12.0034 4.8418V14.7602L12.104 14.8605L16.7079 12.1391L12.104 4.5Z" fill="#343434"/>
    <path d="M12.1039 4.5L7.5 12.1391L12.1039 14.8606V10.0465V4.5Z" fill="#8C8C8C"/>
    <path d="M12.104 15.7322L12.0473 15.8013V19.3345L12.104 19.5L16.7107 13.0122L12.104 15.7322Z" fill="#3C3C3B"/>
    <path d="M12.1039 19.4999V15.7322L7.5 13.0121L12.1039 19.4999Z" fill="#8C8C8C"/>
    <path d="M12.1039 14.8605L16.7078 12.1391L12.1039 10.0466V14.8605Z" fill="#141414"/>
    <path d="M7.5 12.1391L12.1039 14.8605V10.0466L7.5 12.1391Z" fill="#393939"/>
    </svg>
);

