export const Constants = {
  ETH_CHAINID: 4,
  BSC_CHAINID: 97,
  NETWORKS: [
    {
      // Ethereum Rinkeby
      chainId: "0x4",
      networkId: 4,
      chainName: "Ethereum Rinkeby",
      rpcUrls: [
        "https://rinkeby.infura.io/v3/b6690c155e5843c7804086e0845dd380",
      ],
    },
    {
      // Binance Mainnet
      chainId: "0x38",
      networkId: 56,
      chainName: "BSC (Mainnet)",
      rpcUrls: ["https://bsc-dataseed.binance.org/"],
    },
    {
      // Binance Testnet
      chainId: "0x61",
      networkId: 97,
      chainName: "BSC (Testnet)",
      rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    },
  ],
  TOKENS: [
    {
      // Ethereum Rinkeby
      networkId: 4,
      address: "0x7aa5b7740947C62375ED162649932fA257EA275d",
      symbol: "INTI",
      decimals: "9",
      image: "",
    },
    {
      // Binance Testnet
      networkId: 97,
      address: "0xd88C6895d90a719f7f9cCF4debc64EAc55a9DA88",
      symbol: "SAFEROCKET",
      decimals: "9",
      image: "",
    },
  ],
};
