import React from "react";

export const IntiIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_115_244)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37259 18.6274 0 12 0C5.37255 0 -3.8147e-05 5.37259 -3.8147e-05 12C-3.8147e-05 18.6274 5.37255 24 12 24Z"
        fill="#E6273E"
      />
      <path
        d="M13.1882 5.03741C13.4476 4.97256 13.6941 4.99202 13.9275 5.09578C14.2777 5.2644 14.4334 5.56271 14.3945 5.99073C14.3815 6.05558 14.3685 6.11395 14.3556 6.16583C14.2129 6.6587 13.8886 6.98944 13.3828 7.15806C13.3568 7.15806 13.3309 7.16454 13.305 7.17751C12.9288 7.28128 12.6111 7.20345 12.3516 6.94405C12.0533 6.63276 12.0209 6.25662 12.2544 5.81563C12.436 5.40058 12.7472 5.14118 13.1882 5.03741ZM11.4567 9.29816C11.6383 9.25925 11.8717 9.25276 12.1571 9.2787C12.9094 9.42138 13.3828 9.82994 13.5773 10.5044C13.6422 10.7768 13.6422 11.0621 13.5773 11.3604L12.7018 13.734C12.1182 15.2645 11.8199 16.0622 11.8069 16.127C11.6512 16.6329 11.6448 16.9571 11.7874 17.0998C11.8263 17.1257 11.8977 17.1387 12.0014 17.1387C12.1441 17.1387 12.2349 17.1322 12.2738 17.1193C12.8315 16.9247 13.2596 16.3475 13.5579 15.3877C13.5838 15.3358 13.6033 15.2775 13.6162 15.2126C13.6811 15.0181 13.746 14.9143 13.8108 14.9013C13.8497 14.8884 13.9664 14.8819 14.161 14.8819C14.4074 14.8819 14.5436 14.8948 14.5696 14.9208C14.6344 14.9727 14.6474 15.0829 14.6085 15.2515C14.362 16.1076 13.9729 16.8015 13.4411 17.3333C13.078 17.6705 12.7083 17.8975 12.3322 18.0142C12.2025 18.0401 12.0274 18.0531 11.8069 18.0531C11.5734 18.0531 11.4048 18.0401 11.301 18.0142C10.886 17.9104 10.5747 17.7224 10.3672 17.45C10.2115 17.2684 10.1078 17.0609 10.0559 16.8274C9.99105 16.5421 9.99105 16.2502 10.0559 15.9519L10.9509 13.5589C11.5475 11.9636 11.8653 11.0881 11.9042 10.9324C11.956 10.7119 11.956 10.5174 11.9042 10.3488C11.8393 10.1931 11.6966 10.1412 11.4761 10.1931C11.087 10.2709 10.7368 10.5692 10.4256 11.0881C10.231 11.4123 10.0754 11.8079 9.95862 12.2748C9.91971 12.3527 9.89377 12.3981 9.8808 12.411C9.85486 12.437 9.71867 12.4499 9.47224 12.4499H9.10258L9.04422 12.3916C8.97937 12.3397 8.98585 12.2035 9.06367 11.983C9.38793 10.8935 9.91323 10.1088 10.6396 9.6289C10.7044 9.58999 10.7758 9.55108 10.8536 9.51217C11.087 9.38247 11.2881 9.31113 11.4567 9.29816Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_115_244">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
