import { inject, observer } from "mobx-react";
import React from "react";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { networkName } from "@/helpers";
import {
  captionStyles,
  linkStyles,
  timelineContentStyles,
  TransferItemStatus,
  TransferItemTitle,
  TransferTimelineStep,
} from "./TransferTimeline";
import { ETHLogoIcon } from "@/icons/ETHLogoIcon";
import { BSCLogoIcon } from "@/icons/BSCLogoIcon";
import { viewTransactionUrl } from "@/helpers/viewTransactionUrl";

export const _TimelineItemBridgeFee = ({
  transferRequest,
  resolveCurrentStep,
  completedAt,
  isProcessing,
  transactionHash,
  confirmations,
}) => {
  // STUBS
  transferRequest = {};
  transferRequest.from = "BSC";
  transferRequest.to = "ETH";

  return (
    <TimelineItem sx={{ minHeight: "130px" }}>
      <TimelineOppositeContent
        sx={{
          m: "auto 0",
          marginTop: "2px",
          paddingRight: "20px",
          maxWidth: "140px",
          transition: "opacity 1s linear",
        }}
        align="right"
        color="white"
      >
        <TransferItemStatus
          completedAt={completedAt}
          isProcessing={isProcessing}
        />
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TransferTimelineStep
          completed={completedAt}
          isProcessing={isProcessing}
        />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={timelineContentStyles}>
        <TransferItemTitle
          icon={
            transferRequest.from === "ETH" ? <ETHLogoIcon /> : <BSCLogoIcon />
          }
        >
          Transferring BSC bridging fee
        </TransferItemTitle>
        <div style={{ margin: "12px" }}>
          {!completedAt && (
            <p style={captionStyles}>
              This is the fee to unlock and transfer your PRT tokens on the{" "}
              {networkName(transferRequest.to)} network.
            </p>
          )}
          {isProcessing && (
            <a
              href={viewTransactionUrl(transferRequest.from, transactionHash)}
              style={linkStyles}
              target="_blank"
              rel="noopener noreferrer"
            >
              Confirming transaction {confirmations} / 6
            </a>
          )}
          {completedAt && (
            <>
              <p style={captionStyles}>Your bridging fee has been paid.</p>
              <a
                href={viewTransactionUrl(transferRequest.from, transactionHash)}
                style={linkStyles}
                target="_blank"
                rel="noopener noreferrer"
              >
                View transaction
              </a>
            </>
          )}
        </div>
      </TimelineContent>
    </TimelineItem>
  );
};

const mapMobxToProps = ({ transfer }) => {
  const record = transfer.transactionMap.get("bridge:fee");

  return {
    transferRequest: transfer.transferRequest,
    resolveCurrentStep: transfer.resolveCurrentStep,
    isCompleted: record.isCompleted,
    transaction: record.transaction,
    isProcessing: record.isProcessing,
    createdAt: record.createdAt,
    completedAt: record.completedAt,
    transactionHash: record.transactionHash,
    confirmations: record.confirmations,
  };
};

export const TimelineItemBridgeFee = inject(mapMobxToProps)(
  observer(_TimelineItemBridgeFee)
);
